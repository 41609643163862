var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apply_detail" },
    [
      _c("TForm", {
        ref: "htmlFrom",
        attrs: {
          autoCheck: true,
          model: _vm.htmlDatas,
          formlist: _vm.formlist,
          "label-width": "120px",
          rowGutter: 16,
        },
        scopedSlots: _vm._u([
          {
            key: "tagsArrs",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  [
                    _c("TagBind", {
                      attrs: {
                        tagValueList: _vm.htmlDatas[scope.current.keys],
                        tagType: "",
                        notAuto: true,
                        limit: 10,
                        maxlength: 6,
                        addName: "添加标签",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "d_imgs" },
        [_c("TImgVideo", { attrs: { model: _vm.resourceArrs } })],
        1
      ),
      _vm.formlist.length < 1 && _vm.resourceArrs.length < 1
        ? _c("div", { staticClass: "noData" }, [_vm._v("暂无数据")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }