var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.detailLoading,
          expression: "detailLoading",
        },
      ],
      staticClass: "orderDetail",
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-card",
            { staticClass: "order-status" },
            [
              _c(
                "el-steps",
                { attrs: { active: _vm.active, "align-center": "" } },
                [
                  _c("el-step", {
                    attrs: {
                      title: _vm.$t("base.monitor.autoOrder"),
                      icon: "el-icon-s-order",
                    },
                  }),
                  _vm.order.orderStatus === 6
                    ? [
                        _c("el-step", {
                          attrs: {
                            title: _vm.$t("order.declareCheckFailure"),
                            status: "error",
                            icon: "el-icon-s-release",
                          },
                        }),
                      ]
                    : [
                        _c("el-step", {
                          attrs: {
                            title: _vm.$t("order.approve"),
                            icon: "el-icon-s-claim",
                          },
                        }),
                      ],
                  _c("el-step", {
                    attrs: {
                      title: _vm.$t("order.waitReceive"),
                      icon: "el-icon-collection-tag",
                    },
                  }),
                  _c("el-step", {
                    attrs: {
                      title:
                        _vm.order.isSign == "Y"
                          ? "待签到"
                          : _vm.$t("order.waitExecute"),
                      icon: "el-icon-truck",
                    },
                  }),
                  _vm.order.orderStatus === 9
                    ? [
                        _vm.isExecutingClose
                          ? _c("el-step", {
                              attrs: {
                                title: _vm.$t("order.executing"),
                                icon: "el-icon-video-play",
                              },
                            })
                          : _vm._e(),
                        _c("el-step", {
                          attrs: {
                            title: "关单待审核",
                            icon: "el-icon-folder-checked",
                          },
                        }),
                        _c("el-step", {
                          attrs: {
                            title: "已关单",
                            icon: "el-icon-switch-button",
                          },
                        }),
                      ]
                    : [
                        _c("el-step", {
                          attrs: {
                            title: _vm.$t("order.executing"),
                            icon: "el-icon-video-play",
                          },
                        }),
                        _c("el-step", {
                          attrs: {
                            title: _vm.$t("order.completedWaitCheck"),
                            icon: "el-icon-s-check",
                          },
                        }),
                        _c("el-step", {
                          attrs: {
                            title: _vm.$t("order.completed"),
                            icon: "el-icon-circle-check",
                          },
                        }),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "warpBox" },
        [
          _c(
            "el-col",
            { staticClass: "order-row leftPart", attrs: { span: 19 } },
            [
              _c(
                "div",
                { ref: "leftWarp", attrs: { id: "leftWarp" } },
                [
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: _vm.$t("order.details"),
                            name: "first",
                          },
                        },
                        [
                          _c(
                            "el-card",
                            { staticClass: "order-card detailBox" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-form",
                                    { attrs: { "label-width": "100px" } },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "base.position.project"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.order.projectName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t(
                                                        "order.declareTime"
                                                      ),
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.order
                                                          .creationDateStr
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.order.location || _vm.order.deviceName
                                        ? [
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "base.device.position"
                                                          ),
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.order.location
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm.order.deviceName
                                                  ? [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 12 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.$t(
                                                                    "base.device.name"
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.order
                                                                      .deviceName
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "order.orderConfigName"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.order.configName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t("order.orderType"),
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.order.orderTypeStr
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t("order.level"),
                                                  },
                                                },
                                                [
                                                  _vm.order.orderLevel == 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "order.config.ordinary"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm.order.orderLevel == 1
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "order.config.urgent"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm.order.orderLevel == 2
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "order.config.alarm"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t(
                                                        "order.declareDept"
                                                      ),
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.order
                                                          .submitUserDeptName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "order.declarePeople"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.order.submitUserName
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "20px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order
                                                            .submitUserPhone
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t(
                                                        "order.serviceDept"
                                                      ),
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.order.resDeptName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "order.servicePeople"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.order
                                                          .handlePersonName
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "20px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order
                                                            .handlePersonPhone
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.$t("order.faultDesc"),
                                                  },
                                                },
                                                [
                                                  _vm.order.alarmVo
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.alarmVoDetail
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.order
                                                              .contentDesc
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.order.isSign == "Y"
                                            ? _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "签到地址",
                                                      },
                                                    },
                                                    [
                                                      _vm.order.orderSign
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.order
                                                                  .orderSign
                                                                  .detailAddress
                                                              )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.order.subResource &&
                                      _vm.order.subResource.length > 0
                                        ? _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c("el-form-item", {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "order.livePhoto"
                                                        ),
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "pdl32" },
                                                    [
                                                      _c(
                                                        "viewer",
                                                        {
                                                          attrs: {
                                                            images:
                                                              _vm.order
                                                                .subResource,
                                                          },
                                                        },
                                                        [
                                                          _vm._l(
                                                            _vm.order
                                                              .subResource,
                                                            function (
                                                              src,
                                                              index
                                                            ) {
                                                              return [
                                                                !src.fileType ||
                                                                (src.fileType &&
                                                                  src.fileType.includes(
                                                                    "image"
                                                                  ))
                                                                  ? _c("img", {
                                                                      key: index,
                                                                      staticClass:
                                                                        "itImg",
                                                                      attrs: {
                                                                        src: _vm.getImageUrl(
                                                                          src.fileId
                                                                        ),
                                                                      },
                                                                    })
                                                                  : _vm._e(),
                                                                src.fileType &&
                                                                src.fileType.includes(
                                                                  "video"
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "itImg itVedio",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.toPrevVedio(
                                                                                src
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticClass:
                                                                              "vimg",
                                                                            attrs:
                                                                              {
                                                                                src: _vm.getImageUrl(
                                                                                  src.thumbnailId
                                                                                ),
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "playIcon el-icon-video-play",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.order.source == 2
                        ? [
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "申请详情", name: "second" } },
                              [
                                _c("applyDetail", {
                                  attrs: { dataId: _vm.order.orderId },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "用户评价", name: "three" } },
                              [
                                _c("userEvaluate", {
                                  attrs: { dataId: _vm.order.orderId },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "el-card",
                    { staticClass: "order-card" },
                    [
                      _c(
                        "span",
                        { staticClass: "card-title" },
                        [
                          _c("title-icon"),
                          _vm._v(_vm._s(_vm.$t("order.completeDetail"))),
                        ],
                        1
                      ),
                      _vm.order.orderMaterielSonList.length > 0
                        ? [
                            _c(
                              "span",
                              { staticStyle: { float: "right" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-top": "-5px" },
                                    attrs: {
                                      type: "text",
                                      icon: "el-icon-view",
                                    },
                                    on: { click: _vm.openMaterialDetail },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("order.materielDetail"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _c("el-divider", { staticClass: "card-divider" }),
                      _c(
                        "el-row",
                        { staticClass: "order-info" },
                        [
                          _vm.order.orderStatus == 4 ||
                          _vm.order.orderStatus == 5 ||
                          _vm.order.orderStatus == 9
                            ? [
                                _vm.order.orderStatus == 9
                                  ? _c(
                                      "el-form",
                                      { attrs: { "label-width": "100px" } },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "order.completedTime"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order
                                                            .requestDateStr
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "关单时间",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order.closeDateStr
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "关单原因",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order.closeReason
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _vm.order.signResource.length >
                                                1
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "签字图片",
                                                        },
                                                      },
                                                      [
                                                        _vm.order.signResource
                                                          ? _c(
                                                              "div",
                                                              _vm._l(
                                                                _vm.order
                                                                  .signResource,
                                                                function (
                                                                  it,
                                                                  dex
                                                                ) {
                                                                  return _c(
                                                                    "img",
                                                                    {
                                                                      key: dex,
                                                                      staticClass:
                                                                        "signPic",
                                                                      attrs: {
                                                                        src: _vm.getImageUrl(
                                                                          it.fileId
                                                                        ),
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "关单情况",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "viewer",
                                                      {
                                                        attrs: {
                                                          images:
                                                            _vm.order
                                                              .closeResource,
                                                        },
                                                      },
                                                      [
                                                        _vm._l(
                                                          _vm.order
                                                            .closeResource,
                                                          function (
                                                            src,
                                                            index
                                                          ) {
                                                            return [
                                                              !src.fileType ||
                                                              (src.fileType &&
                                                                src.fileType.includes(
                                                                  "image"
                                                                ))
                                                                ? _c("img", {
                                                                    key: index,
                                                                    staticClass:
                                                                      "itImg",
                                                                    attrs: {
                                                                      src: _vm.getImageUrl(
                                                                        src.fileId
                                                                      ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              src.fileType &&
                                                              src.fileType.includes(
                                                                "video"
                                                              )
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "itImg itVedio",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.toPrevVedio(
                                                                              src
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "vimg",
                                                                          attrs:
                                                                            {
                                                                              src: _vm.getImageUrl(
                                                                                src.thumbnailId
                                                                              ),
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "playIcon el-icon-video-play",
                                                                      }),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-form",
                                      { attrs: { "label-width": "100px" } },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "order.completedTime"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order
                                                            .requestDateStr
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "order.submitTime"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order.orderImp
                                                            .lastUpdateDateStr
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "order.completedHappening"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.order.orderImp
                                                            .completeSituation
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _vm.order.isAutograph == "Y"
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "签字图片",
                                                        },
                                                      },
                                                      [
                                                        _vm.order.signResource
                                                          ? _c(
                                                              "div",
                                                              _vm._l(
                                                                _vm.order
                                                                  .signResource,
                                                                function (
                                                                  it,
                                                                  dex
                                                                ) {
                                                                  return _c(
                                                                    "img",
                                                                    {
                                                                      key: dex,
                                                                      staticClass:
                                                                        "signPic",
                                                                      attrs: {
                                                                        src: _vm.getImageUrl(
                                                                          it.fileId
                                                                        ),
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.order.orderImp &&
                                        _vm.order.orderImp.implementResource
                                          .length > 0
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 24 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "order.completeState"
                                                          ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "viewer",
                                                          {
                                                            attrs: {
                                                              images:
                                                                _vm.order
                                                                  .orderImp
                                                                  .implementResource,
                                                            },
                                                          },
                                                          [
                                                            _vm._l(
                                                              _vm.order.orderImp
                                                                .implementResource,
                                                              function (
                                                                src,
                                                                index
                                                              ) {
                                                                return [
                                                                  !src.fileType ||
                                                                  (src.fileType &&
                                                                    src.fileType.includes(
                                                                      "image"
                                                                    ))
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "itImg",
                                                                          attrs:
                                                                            {
                                                                              src: _vm.getImageUrl(
                                                                                src.fileId
                                                                              ),
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  src.fileType &&
                                                                  src.fileType.includes(
                                                                    "video"
                                                                  )
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "itImg itVedio",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.toPrevVedio(
                                                                                  src
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "vimg",
                                                                              attrs:
                                                                                {
                                                                                  src: _vm.getImageUrl(
                                                                                    src.thumbnailId
                                                                                  ),
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "playIcon el-icon-video-play",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ]
                            : [
                                _c("div", { staticClass: "no-data" }, [
                                  _vm._v(_vm._s(_vm.$t("commons.noData"))),
                                ]),
                              ],
                        ],
                        2
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _c("el-col", { attrs: { span: 5 } }, [
            _c(
              "div",
              { style: _vm.autoHei },
              [
                _c(
                  "el-card",
                  { staticClass: "order-card h100" },
                  [
                    _c(
                      "span",
                      { staticClass: "card-title" },
                      [
                        _c("title-icon"),
                        _vm._v(_vm._s(_vm.$t("order.dynamic"))),
                      ],
                      1
                    ),
                    _c("el-divider", { staticClass: "card-divider" }),
                    _c(
                      "el-row",
                      { staticClass: "order-info" },
                      [
                        _c(
                          "el-timeline",
                          _vm._l(
                            _vm.order.orderDynamicList,
                            function (item, index) {
                              return _c(
                                "el-timeline-item",
                                {
                                  key: index,
                                  attrs: { timestamp: item.creationDateStr },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(item.userName) +
                                        " " +
                                        _vm._s(item.operationName)
                                    ),
                                  ]),
                                  item.explainCard
                                    ? _c("div", { staticClass: "d_tips" }, [
                                        _vm._v(_vm._s(item.explainCard)),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { top: "5vh", visible: _vm.dialogMaterialVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogMaterialVisible = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("title-icon"),
              _vm._v(_vm._s(_vm.$t("order.materielDetail")) + "\n      "),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { "margin-bottom": "15px" },
              attrs: {
                data: _vm.order.orderMaterielSonList,
                border: "",
                fit: "",
                stripe: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  align: "center",
                  resizable: false,
                  label: _vm.$t("commons.index"),
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "materielName",
                  label: _vm.$t("base.materiel.materielName"),
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "number",
                  label: _vm.$t("facilityHome.number"),
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specifications",
                  label: _vm.$t("base.materiel.unit"),
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: _vm.$t("order.appPerson"),
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deptName",
                  label: _vm.$t("order.appDept"),
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("order.appTime"), width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (s) {
                      return [
                        s.row.creationDate != null
                          ? _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      s.row.creationDate,
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }