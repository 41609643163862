<template>
	<div class="apply_detail">
		<TForm  ref="htmlFrom" :autoCheck='true' :model="htmlDatas" :formlist="formlist" label-width='100px' :rowGutter='16' >
			<template slot="tagsArrs" slot-scope="scope">
				<div>
					<TagBind :tagValueList="htmlDatas[scope.current.keys]" tagType :notAuto='true' :limit='10' :maxlength='6' addName='添加标签'/>
				</div>
			</template>
		</TForm>
		
		<div class="d_imgs">
			
			<TImgVideo :model='resourceArrs'/> 
		</div>
		<div v-if="formlist.length<1&&resourceArrs.length<1" class="noData">暂无数据</div>
	</div>
</template>
<script>
	import TForm from '@/components/YTable/TForm.vue';
	import TImgVideo from '@/components/YTable/TImgVideo.vue';
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
		},
		components: {TForm,TImgVideo},
		data() {
			return {
				resourceArrs:[],
				htmlDatas:{
	  				tagList:[],//标签
	  			},
	  			formlist:[],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.getDatasById();
				}
			},
			
		},
		created() {
//			let infoArr=[
//				{keys: 'userName',name: '用户名称',value: '张三',type: 'text'},
//				{keys: 'telPhone',name: '联系电话',value: '18146628123',type: 'text'},
//				{keys: 'wxName',name: '微信昵称',value: '',type: 'text'},//文本
//				{keys: 'shenfenzheng',name: '身份证',value: [
//					{fileId: 31840,fileType: "image/jpeg"},//图片
//					{fileId: 31842,fileType: "video/mp4",thumbnailId: 31838},//视频
//				],type: 'imgVedios'},
//			]
			this.resetTFrom();
			this.getDatasById();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
			async  getDatasById(){
				if(!this.dataId) return;
				let params={
					orderId:this.dataId,
					tenantId: this.$store.getters.currentTenant,
				}
				let res = await this.ApiHttp('/customer/wx/order/queryOrderDetail',params);
		    	if(res.detail){
		    		this.formlist=[];
		    		this.resourceArrs=[];
					res.detail.map(it=>{
						if(it.type=='text'&&it.value){
							it.flex=12;
							this.formlist.push(it)
						}
						if(it.type=='imgVedios'){
							it.value=it.value||[];
							this.resourceArrs=this.resourceArrs.concat(it.value);
						}
					})
					this.resetTFrom();
		    	}
			},
			getFromDatas(){
				let infos={
					...this.htmlDatas,
				}
				this.$refs.htmlFrom.validate(res=>{//进行校验
					if(!res){
						this.$message({message:'请检查输入是否正确',type:'warning'})
						return;
					}
					
				})
				
				
			},
		},

	}
</script>

<style lang="scss">
.apply_detail{
	padding: 20px;
	min-height: 280px;
	margin-bottom: 18px;
    border: 1px solid #EBEEF5;
	box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
	.Y-myFrom .el-form-item{
		margin-bottom: 0;
		.el-form-item__label{
			color: #909399;
		}
	}
	.d_imgs{
		padding: 0 0 0 36px;
	}
	.noData{
		text-align: center;
		line-height: 180px;
		color: #999;
	}
}

</style>