<template>
	<div class="orderDetail" v-loading="detailLoading">
		<el-row>
			<el-card class="order-status">
				<el-steps :active="active" align-center>
					<el-step :title="$t('base.monitor.autoOrder')" icon="el-icon-s-order"></el-step>
					<template v-if="order.orderStatus === 6">
						<el-step :title="$t('order.declareCheckFailure')" status="error" icon="el-icon-s-release"></el-step>
					</template>
					<template v-else>
						<el-step :title="$t('order.approve')" icon="el-icon-s-claim"></el-step>
					</template>
					<el-step :title="$t('order.waitReceive')" icon="el-icon-collection-tag"></el-step>
					<el-step :title="order.isSign=='Y'?'待签到':$t('order.waitExecute')" icon="el-icon-truck"></el-step>
					<!--如果配置签到功能  就将待执行改成待签到-->
					<template v-if="order.orderStatus === 9">
						<el-step v-if='isExecutingClose' :title="$t('order.executing')" icon="el-icon-video-play"></el-step>
						<el-step title="关单待审核" icon="el-icon-folder-checked"></el-step>
						<el-step title="已关单" icon="el-icon-switch-button"></el-step>
					</template>
					<template v-else>
						<el-step :title="$t('order.executing')" icon="el-icon-video-play"></el-step>
						<el-step :title="$t('order.completedWaitCheck')" icon="el-icon-s-check"></el-step>
						<el-step :title="$t('order.completed')" icon="el-icon-circle-check"></el-step>
					</template>

				</el-steps>
			</el-card>
		</el-row>
		<el-row class='warpBox'>
			<el-col :span="19" class="order-row leftPart">
				<div ref='leftWarp' id="leftWarp">
					<el-tabs v-model="activeName" >
					    <el-tab-pane :label='$t("order.details")' name="first">
					    	
					    	
					
					
						<el-card class="order-card detailBox">
						<!--工单详情-->
						<!--<span class="card-title"><title-icon />{{ $t("order.details") }}</span>
						<el-divider class="card-divider"></el-divider>-->
						<el-row>
							<el-form label-width="100px">
								<el-row>
									<el-col :span="12">
										<el-form-item :label="$t('base.position.project')">
											<span>{{ order.projectName }}</span>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item :label="$t('order.declareTime')">
											<span>{{ order.creationDateStr }}</span>
										</el-form-item>
									</el-col>
								</el-row>
								<template v-if="order.location || order.deviceName">
									<el-row>
										<el-col :span="12">
											<el-form-item :label="$t('base.device.position')">
												<span>{{ order.location }}</span>
											</el-form-item>
										</el-col>
										<template v-if="order.deviceName">
											<el-col :span="12">
												<el-form-item :label="$t('base.device.name')">
													<span>{{ order.deviceName }}</span>
												</el-form-item>
											</el-col>
										</template>
									</el-row>
								</template>
								<el-row>
									<el-col :span="12">
										<el-form-item :label="$t('order.orderConfigName')">
											<span>{{ order.configName }}</span>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item :label="$t('order.orderType')">
											<span>{{ order.orderTypeStr }}</span>
											<!--<template v-if="order.orderType == 1">
											<span>{{ $t("order.device") }}</span>
										</template>
										<template v-if="order.orderType == 2">
											<span>{{ $t("order.fireSafety") }}</span>
										</template>
										<template v-if="order.orderType == 3">
											<span>{{ $t("order.quality") }}</span>
										</template>
										<template v-if="order.orderType == 4">
											<span>{{ $t("order.sporadicMaintenance") }}</span>
										</template>
										<template v-if="order.orderType == 5">
											<span>{{ $t("order.cleaningHygiene") }}</span>
										</template>
										<template v-if="order.orderType == 6">
											<span>{{ $t("order.greenConservation") }}</span>
										</template>
										<template v-if="order.orderType == 7">
											<span>{{ $t("order.vulnerabilityInformation") }}</span>
										</template>
										<template v-if="order.orderType == 8">
											<span>{{ $t("order.customerDeclaration") }}</span>
										</template>-->
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item :label="$t('order.level')">
											<span v-if="order.orderLevel == 0">{{
                    $t("order.config.ordinary")
                  }}</span>
											<span v-else-if="order.orderLevel == 1">{{
                    $t("order.config.urgent")
                  }}</span>
											<span v-else-if="order.orderLevel == 2">{{
                    $t("order.config.alarm")
                  }}</span>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item :label="$t('order.declareDept')">
											<span>{{ order.submitUserDeptName }}</span>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item :label="$t('order.declarePeople')">
											<span>{{ order.submitUserName }}</span>
											<span style="margin-left: 20px">{{
                    order.submitUserPhone
                  }}</span>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item :label="$t('order.serviceDept')">
											<span>{{ order.resDeptName }}</span>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item :label="$t('order.servicePeople')">
											<span>{{ order.handlePersonName }}</span>
											<span style="margin-left: 20px">{{
                    order.handlePersonPhone
                  }}</span>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item :label="$t('order.faultDesc')">
											<span v-if="order.alarmVo">{{alarmVoDetail}}</span>
											<span v-else>{{ order.contentDesc }}</span>
										</el-form-item>
									</el-col>
									<el-col :span="12" v-if='order.isSign=="Y"'>
										<el-form-item label="签到地址">
											<span v-if='order.orderSign'>{{ order.orderSign.detailAddress }}</span>
										</el-form-item>
									</el-col>
								</el-row>
								<!--现场图片-->
								<el-row v-if="order.subResource && order.subResource.length > 0">
									<el-col :span="24">
										<el-form-item :label="$t('order.livePhoto')">
										</el-form-item>
										<div class="pdl32">
											<viewer :images="order.subResource">
												<template v-for="(src, index) in order.subResource">
													<img v-if="
                          !src.fileType ||
                          (src.fileType && src.fileType.includes('image'))
                        " class="itImg" :src="getImageUrl(src.fileId)" :key="index" />
													<span class="itImg itVedio" v-if="src.fileType && src.fileType.includes('video')" @click.stop="toPrevVedio(src)">
                								<img class="vimg" :src="getImageUrl(src.thumbnailId)" />
                								<i  class="playIcon el-icon-video-play" ></i>
                    						</span>
												</template>

											</viewer>
										</div>
									</el-col>
								</el-row>

							</el-form>
						</el-row>
					</el-card>
					</el-tab-pane>
					<template v-if="order.source==2">
						
						<el-tab-pane label="申请详情" name="second">
							<applyDetail :dataId='order.orderId'/>
						</el-tab-pane>
						<el-tab-pane label="用户评价" name="three">
							<userEvaluate :dataId='order.orderId'/>
						</el-tab-pane>
					</template>
				  	</el-tabs>
					<el-card class="order-card">
						<!--完成详情-->
						<span class="card-title"><title-icon />{{ $t("order.completeDetail") }}</span>
						<template v-if="order.orderMaterielSonList.length > 0">
							<span style="float: right">
              <el-button
                type="text"
                icon="el-icon-view"
                style="margin-top: -5px"
                @click="openMaterialDetail"
                >{{ $t("order.materielDetail") }}</el-button
              >
            </span>
						</template>
						<el-divider class="card-divider"></el-divider>
						<el-row class="order-info">
							<template v-if="order.orderStatus == 4 || order.orderStatus == 5||order.orderStatus == 9">
								<el-form label-width="100px" v-if='order.orderStatus== 9'>
									<el-row>
										<el-col :span="12">
											<el-form-item :label="$t('order.completedTime')">
												<span>{{ order.requestDateStr }}</span>
											</el-form-item>
										</el-col>
										<el-col :span="12">
											<el-form-item label="关单时间">
												<span>{{ order.closeDateStr }}</span>
											</el-form-item>
										</el-col>
										<el-col :span="12">
											<el-form-item label="关单原因">
												<span>{{ order.closeReason }}</span>
											</el-form-item>
										</el-col>
									</el-row>
									<el-row>
										<el-col :span="24">
											<el-form-item label="签字图片" v-if='order.signResource.length>1'>
												<div v-if="order.signResource">
													<img v-for="(it,dex) in order.signResource" :key='dex' class="signPic" :src="getImageUrl(it.fileId)" />
												</div>
											</el-form-item>
										</el-col>
									</el-row>
									<!--关单图片-->
									<el-row>
										<el-col :span="24">
											<el-form-item label="关单情况">
												<viewer :images="order.closeResource">
													<template v-for="(src, index) in order.closeResource">
														<img v-if="!src.fileType ||(src.fileType && src.fileType.includes('image'))" class="itImg" :src="getImageUrl(src.fileId)" :key="index" />
														<span class="itImg itVedio" v-if="src.fileType && src.fileType.includes('video')" @click="toPrevVedio(src)">
		                    							<img class="vimg" :src="getImageUrl(src.thumbnailId)" />
                										<i  class="playIcon el-icon-video-play" ></i>
		                    						</span>
													</template>
												</viewer>
											</el-form-item>
										</el-col>
									</el-row>
								</el-form>
								<el-form label-width="100px" v-else>
									<el-row>
										<el-col :span="12">
											<el-form-item :label="$t('order.completedTime')">
												<span>{{ order.requestDateStr }}</span>
											</el-form-item>
										</el-col>
										<el-col :span="12">
											<el-form-item :label="$t('order.submitTime')">
												<span>{{ order.orderImp.lastUpdateDateStr }}</span>
											</el-form-item>
										</el-col>
									</el-row>
									<el-row>
										<el-col :span="12">
											<el-form-item :label="$t('order.completedHappening')">
												<span>{{ order.orderImp.completeSituation }}</span>
											</el-form-item>
										</el-col>
									</el-row>
									<el-row>
										<el-col :span="24">
											<el-form-item label="签字图片" v-if='order.isAutograph=="Y"'>
												<div v-if="order.signResource">
													<img v-for="(it,dex) in order.signResource" :key='dex' class="signPic" :src="getImageUrl(it.fileId)" />
												</div>

											</el-form-item>
										</el-col>
									</el-row>
									<el-row v-if="order.orderImp &&order.orderImp.implementResource.length > 0">
										<el-col :span="24">
											<el-form-item :label="$t('order.completeState')">
												<viewer :images="order.orderImp.implementResource">
													<template v-for="(src, index) in order.orderImp.implementResource">
														<img v-if="!src.fileType ||(src.fileType && src.fileType.includes('image'))" class="itImg" :src="getImageUrl(src.fileId)" :key="index" />
														<span class="itImg itVedio" v-if="src.fileType && src.fileType.includes('video')" @click="toPrevVedio(src)">
		                    							<img class="vimg" :src="getImageUrl(src.thumbnailId)" />
                										<i  class="playIcon el-icon-video-play" ></i>
		                    						</span>
													</template>
												</viewer>
											</el-form-item>
										</el-col>
									</el-row>
								</el-form>
							</template>
							<template v-else>
								<div class="no-data">{{ $t("commons.noData") }}</div>
							</template>
						</el-row>
					</el-card>
				</div>
			</el-col>
			<el-col :span="5">
				<div :style="autoHei">
					<el-card class="order-card h100">
						<span class="card-title"><title-icon />{{ $t("order.dynamic") }}</span
          >
          <el-divider class="card-divider"></el-divider>
          <el-row class="order-info">
            <el-timeline>
              <el-timeline-item
                v-for="(item, index) in order.orderDynamicList"
                :key="index"
                :timestamp="item.creationDateStr"
              >
                <div>{{ item.userName }} {{ item.operationName }}</div>
                <div class='d_tips' v-if='item.explainCard'>{{ item.explainCard }}</div>
              </el-timeline-item>
            </el-timeline>
          </el-row>
        </el-card>
				</div>
				
    		
    		
    	</el-col>
    	
    </el-row>
    <!--<el-button type="text"  @click="toPrevVedio" >视频预览</el-button >-->
    <el-dialog top="5vh" :visible.sync="dialogMaterialVisible">
      <template slot="title">
        <title-icon />{{ $t("order.materielDetail") }}
      </template>
      <el-table
        :data="order.orderMaterielSonList"
        style="margin-bottom: 15px"
        border
        fit
        stripe
      >
        <el-table-column
          type="index"
          align="center"
          :resizable="false"
          :label="$t('commons.index')"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="materielName"
          :label="$t('base.materiel.materielName')"
          width="160"
        >
        </el-table-column>
        <el-table-column
          prop="number"
          :label="$t('facilityHome.number')"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="specifications"
          :label="$t('base.materiel.unit')"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="userName"
          :label="$t('order.appPerson')"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="deptName"
          :label="$t('order.appDept')"
          width="100"
        >
        </el-table-column>
        <el-table-column :label="$t('order.appTime')" width="180">
          <template slot-scope="s">
            <span v-if="s.row.creationDate != null">
              {{ s.row.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderDetail } from "@/api/business/order/tenant/order";
import { envInfo } from "@/constants/envInfo";
import {getVedioSrc } from '@/utils/download.js';
import handsRlink from "@/mixins/handsRlink.js";
import applyDetail from "./applyDetail.vue";
import userEvaluate from "./userEvaluate.vue";
export default {
  name: "OrderDetail",
  components: {applyDetail,userEvaluate},
  mixins: [handsRlink],
  props: {
    orderRow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      active: 0,
      detailLoading: false,
      order: {
      	stepArrs:[],
        orderId: null,
        deviceName: "",
        location: "",
        submitUserDeptName: "",
        submitUserName: "",
        submitUserPhone: "",
        resDeptName: "",
        handlePersonName: "",
        handlePersonPhone: "",
        creationDateStr: "",
        orderLevel: "",
        submitPhoto: [],
        contentDesc: "",
        orderDynamicList: [],
        orderMaterielSonList: [],
      },
      oConfig:{
      	isAutograph:'N',
      },//订单配置
      dialogMaterialVisible: false,
      autoHei:{},//自动高度样式
      isExecutingClose:false,//判断是否执行中进行关单
      alarmVoDetail:'',
      activeName:'first',
    };
  },
  created() {
    this.getOrderDetail();
//  this.getOrderConfig();
  },
  mounted(){
  },
  methods: {
  	toPrevVedio(it){
  		
  		let src=getVedioSrc(it.fileId)
  		
//		http://localhost:8080/saascloud/saas/archive/param/archive/download?dlType=DefaultDownload&fi=6584
  		this.$store.commit('setVedioSrc',src);
  		
  	},
    getImageUrl(fileId) {
      if (fileId) {
        return (
          envInfo.bgApp.archivePath +
          "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
          fileId
        );
      }
      return "";
    },
    async getOrderConfig(row){
    	//获取工单配置
    	
    	let res = await this.ApiHttp('/order/tenant/orderConfig/getConfigDetails',{
    		isloading:false,
    		orderConfigId:this.orderRow.orderConfigId
    	});
    	this.oConfig={...res};
    },
    
    //详情
    getOrderDetail() {
      this.detailLoading = true;
      this.order.orderId = this.orderRow.orderId;
      getOrderDetail({ orderId: this.order.orderId })
        .then((res) => {
          this.setActive(res.orderStatus);
          if(res.orderDynamicList){
          	let findBy=['sign_order','start_implement'];
          	for(let it of res.orderDynamicList){
          		if(findBy.indexOf(it.operationType)>-1){
          			this.isExecutingClose=true;
          			break;
          		}
          		
          		
          	}
          }
          res.closeDateStr=this.common.getTimeMmss(res.closeDate)
          if(res.alarmVo){
          	const {alarmVo}=res;
          	if(!alarmVo.propertyDescription) alarmVo.propertyDescription='-';
          	if(!alarmVo.propertyUnit) alarmVo.propertyUnit='';
          	let showAlarmValue=alarmVo.alarmValue+' '+alarmVo.propertyUnit;
          	if(!alarmVo.alarmValue) showAlarmValue='-';
          	this.alarmVoDetail='告警类型:'+ this.AlarmTypeObj[alarmVo.alarmType]+
          	'; 告警内容:'+alarmVo.alarmContent+
          	'; 告警值:'+showAlarmValue+
          	'; 告警时间:'+this.common.getTimeMmss(alarmVo.alarmTimestamp)+
          	'; 告警参数:'+alarmVo.propertyDescription
          	
          }
          
          this.order = res;
          this.$nextTick(() => {
		    	setTimeout(()=>{
			      let el= this.$refs['leftWarp'].getBoundingClientRect();
			      if(el.height) this.autoHei={height:el.height+'px'};
		    	},500)
		    	
		    })
        })
        .finally(() => {
          this.detailLoading = false;
        });
    },
    // 设置工单显示状态
    setActive(orderStatus) {
      // 工单已完成
      if (orderStatus === 5) {
        this.active = 999;
      } else if (orderStatus === 6) {
        // 报单审核失败
        this.active = 1;
      } else if (orderStatus === 7) {
        // 完成审核不通过
//      this.active = 3;//old
        this.active = 4;
      } else {
        this.active = orderStatus + 1;
      }
    },
    openMaterialDetail() {
      this.dialogMaterialVisible = true;
    },
  },
};
</script>
<style type="text/css" lang="scss" scoped>
	.itImg{
    	vertical-align: text-top;
	}
	.itVedio{
		position: relative;
		display: inline-block;
		text-align: center;
		font-size: 80px;
		border: 1px solid #CCCCCC;
		.vimg{
			width: 100%;
			height: 100%;
		}
		.playIcon{
			position: absolute;
		    top: 50%;
		    left: 50%;
		    font-size: 48px;
		    margin-left: -24px;
		    margin-top: -24px;
		    color: white;
		}
	}
</style>
<style type="text/css" lang="scss">
.orderDetail {
  margin: 10px 0;
  .warpBox{
    margin-top: 18px;
    .detailBox{
      margin-bottom: 18px;
    }
    .h100{
        height: 100% !important;
    }
    .signPic{
        width: 213px;
        height: 107px;
        border: 1px solid #DBDFEA;
        border-radius: 3px;
    }
    .p_list{
        padding-left: 32px;
        .p_it{
            display: inline-block;
            width: 107px;
            margin: 6px 12px 6px 0;
           
        }
        .p_it:last-child{
            margin-right: 0;
        }
    }
    .pdl32{
        padding-left: 32px;
    }
    .itImg{
        border-radius: 5px;
        width: 107px;
        height: 107px;
        margin: 6px 12px 6px 0;
    }
  }
   
    .leftPart{
        padding-right: 18px;
    }
  .orderImg {
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 1px solid #eee;
    cursor: pointer;
    margin-left: 15px;
    margin-top: 10px;
  }

  

  .order-card {
    .card-title {
      font-size: 16px;
      color: rgb(153, 153, 153);
      font-weight: bold;
    }

    .card-divider {
      margin: 10px 0;
    }

    label {
      color: #909399;
    }

    .el-form-item {
      margin-bottom: 0px;
    }
    .el-card__body{
        height: 100%;
    }
    .order-info {
      height: calc(100% - 40px);
      overflow-y: auto;
      .el-timeline {
        margin-top: 10px;
      }
      .d_tips{
      	color: #909399;
      	font-size: 12px;
      	margin-top: 6px;
      }
    }
  }
}

</style>